<template>
  <div style="padding: 20px; text-align: center">
  <div id="economicCalendarWidget"></div>
  </div>
</template>
<script type="text/javascript" src="https://www.tradays.com/c/js/widgets/calendar/widget.js?8"></script>
<script type="text/javascript">
    new economicCalendar({ width: "100%", height: "100%", mode: 2 });
</script>
<script>
  export default {
    name: "index",
    data() {
      return {
        active: 0
      };
    }
  }
</script>

<style scoped>

</style>
