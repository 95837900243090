<template>
  <div style="padding: 20px; text-align: center">
    通知
  </div>
</template>

<script>
  export default {
    name: "Notify"
  }
</script>

<style scoped>

</style>
